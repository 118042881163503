// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-black-friday-is-around-the-corner-is-your-shopify-store-ready-js": () => import("./../../../src/pages/blog/black-friday-is-around-the-corner-is-your-shopify-store-ready.js" /* webpackChunkName: "component---src-pages-blog-black-friday-is-around-the-corner-is-your-shopify-store-ready-js" */),
  "component---src-pages-blog-black-friday-shopify-checklist-and-tips-js": () => import("./../../../src/pages/blog/black-friday-shopify-checklist-and-tips.js" /* webpackChunkName: "component---src-pages-blog-black-friday-shopify-checklist-and-tips-js" */),
  "component---src-pages-blog-ecommerce-website-budget-guide-js": () => import("./../../../src/pages/blog/ecommerce-website-budget-guide.js" /* webpackChunkName: "component---src-pages-blog-ecommerce-website-budget-guide-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-blog-off-the-shelf-shopify-themes-the-pros-and-cons-js": () => import("./../../../src/pages/blog/off-the-shelf-shopify-themes-the-pros-and-cons.js" /* webpackChunkName: "component---src-pages-blog-off-the-shelf-shopify-themes-the-pros-and-cons-js" */),
  "component---src-pages-blog-unlock-25-more-sales-this-black-friday-the-ultimate-shopify-optimisation-guide-js": () => import("./../../../src/pages/blog/unlock-25-more-sales-this-black-friday-the-ultimate-shopify-optimisation-guide.js" /* webpackChunkName: "component---src-pages-blog-unlock-25-more-sales-this-black-friday-the-ultimate-shopify-optimisation-guide-js" */),
  "component---src-pages-blog-when-to-invest-in-a-new-website-js": () => import("./../../../src/pages/blog/when-to-invest-in-a-new-website.js" /* webpackChunkName: "component---src-pages-blog-when-to-invest-in-a-new-website-js" */),
  "component---src-pages-careers-index-js": () => import("./../../../src/pages/careers/index.js" /* webpackChunkName: "component---src-pages-careers-index-js" */),
  "component---src-pages-case-studies-beyond-nine-js": () => import("./../../../src/pages/case-studies/beyond-nine.js" /* webpackChunkName: "component---src-pages-case-studies-beyond-nine-js" */),
  "component---src-pages-case-studies-coat-paints-js": () => import("./../../../src/pages/case-studies/coat-paints.js" /* webpackChunkName: "component---src-pages-case-studies-coat-paints-js" */),
  "component---src-pages-case-studies-jubel-js": () => import("./../../../src/pages/case-studies/jubel.js" /* webpackChunkName: "component---src-pages-case-studies-jubel-js" */),
  "component---src-pages-case-studies-russell-and-atwell-js": () => import("./../../../src/pages/case-studies/russell-and-atwell.js" /* webpackChunkName: "component---src-pages-case-studies-russell-and-atwell-js" */),
  "component---src-pages-case-studies-seep-js": () => import("./../../../src/pages/case-studies/seep.js" /* webpackChunkName: "component---src-pages-case-studies-seep-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-meet-the-team-js": () => import("./../../../src/pages/meet-the-team.js" /* webpackChunkName: "component---src-pages-meet-the-team-js" */),
  "component---src-pages-shopify-js": () => import("./../../../src/pages/shopify.js" /* webpackChunkName: "component---src-pages-shopify-js" */)
}

